import { Formik } from "formik";
import { Grid, useTheme, Typography } from "@mui/material";
import StyledTextfield from "../UI/StyledTextfield";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useAppDispatch } from "../../redux/hooks";
import { login } from "../../redux/thunks/auth.thunk";
import StyledButton from "../UI/StyledButton";
import { routes } from "../../util/routes";
import oktaLogoText from "../../assets/images/okta-black-text.png";
import oktaLogoAura from "../../assets/images/okta-black-aura.png";

const LoginSchema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});

export interface ILoginFormProps {}

export default function LoginForm(props: ILoginFormProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={async (values) => {
        const action = await dispatch(login(values));
        if (action.payload) {
          navigate("/");
        }
      }}
      validationSchema={LoginSchema}
      validateOnMount={true}
      validateOnChange={true}
    >
      {(formik) => (
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            flexGrow: 1,
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              formik.handleSubmit();
            }
          }}
        >
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledButton
              color="secondary"
              style={{ backgroundColor: "white" }}
              variant="contained"
              buttonStyle="rectangle"
              size="large"
              fullWidth
              onClick={() => navigate("/omni_test/")}
            >
              <img
                style={{ height: "52px", marginRight: "4px" }}
                src={oktaLogoAura}
                alt=""
              />
              <img
                style={{ height: "30px" }}
                src={oktaLogoText}
                alt="Okta logo"
              />
            </StyledButton>
          </Grid>
          <Grid
            item
            sx={{
              marginTop: theme.spacing(3),
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="line"
              style={{ height: "1px", width: "100%", backgroundColor: "#999" }}
            ></div>
            <div
              className="or-text"
              style={{ margin: "0 30px", color: "#555" }}
            >
              OR
            </div>
            <div
              className="line"
              style={{ height: "1px", width: "100%", backgroundColor: "#999" }}
            ></div>
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              name="email"
              fullWidth
              color="primary"
              label="Email Address"
            />
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(2), width: "100%" }}>
            <StyledTextfield
              type="password"
              name="password"
              fullWidth
              color="primary"
              label="Password"
            />
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(4) }}>
            <Link
              to={routes.loginRoutes.resetPassword}
              style={{ textDecoration: "none" }}
            >
              <Typography align="center" color="primary">
                Forgot Password?
              </Typography>
            </Link>
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(4), width: "100%" }}>
            <StyledButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              fullWidth
            >
              Login
            </StyledButton>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
